import { PortSearchCriteria } from "domain/entity/Port/PortSearchCriteria";
import { PortConstant } from "presentation/constant/Port/PortConstant";
import { usePortVM } from "presentation/hook/Port/usePortVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo } from "react";
import { FieldType, HPHButton, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const PortSearchPanel = () => {
    const PORT_CONSTANT = PortConstant.Table;
    const [portState] = usePortTracked();
    const portVM = usePortVM();
    const { isLoading } = portState;
    const messageBarVM = useMessageBarVM();
    const { searchCriteria } = portState;

    const resetButtonClicked = useCallback(() => {
        portVM.onSearchCriteriaResetClick();
    }, [portVM]);

    const searchButtonClicked = useCallback((searchCriteria: PortSearchCriteria) => {
        if (!searchCriteria.portCode && !searchCriteria.geogInd) {
            messageBarVM.showError("Must input Port Code or Geog Ind in search criteria.");
            return;
        }
        portVM.onShowLoading();
        portVM.searchPortList(searchCriteria).then((data) => {
            portVM.onHideLoading();
        }).catch(error => {
            portVM.onHideLoading();
        })

    }, [messageBarVM, portVM])


    const memoProvinceCodeOptions = useMemo(() => {
        const provinceCodeOptions = searchCriteria.countryCode ? portState.dynamicOptions.provinceCodeDropdownOptions[searchCriteria.countryCode] : [];
        return provinceCodeOptions;
    }, [searchCriteria.countryCode, portState.dynamicOptions.provinceCodeDropdownOptions])

    const memoPortCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={searchCriteria?.portCode || ''}
                fieldValue={searchCriteria?.portCode}
                fieldLabel={PORT_CONSTANT.PORT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'portCode'}
                maxLength={60}
                sort={false}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [searchCriteria?.portCode, PORT_CONSTANT.PORT_CODE, portVM])

    const memoCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={searchCriteria?.countryCode || ''}
                fieldValue={searchCriteria?.countryCode}
                fieldLabel={PORT_CONSTANT.COUNTRY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'countryCode'}
                maxLength={60}
                sort={false}
                options={portState.dynamicOptions.countryCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [searchCriteria?.countryCode, PORT_CONSTANT.COUNTRY_CODE, portState.dynamicOptions.countryCodeDropdownOptions, portVM])

    const memoProvinceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={searchCriteria?.provinceCode || ''}
                fieldValue={searchCriteria?.provinceCode}
                fieldLabel={PORT_CONSTANT.PROVINCE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'provinceCode'}
                maxLength={60}
                sort={false}
                options={memoProvinceCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [searchCriteria?.provinceCode, PORT_CONSTANT.PROVINCE_CODE, memoProvinceCodeOptions, portVM])

    const memoGeographicalInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={searchCriteria?.geogInd || ''}
                fieldValue={searchCriteria?.geogInd}
                fieldLabel={PORT_CONSTANT.GEOG_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'geogInd'}
                maxLength={60}
                sort={false}
                options={portState.dynamicOptions.geographicalIndDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    portVM.onSearchFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [searchCriteria?.geogInd, PORT_CONSTANT.GEOG_IND, portState.dynamicOptions.geographicalIndDropdownOptions, portVM])

    return (
        <div className='side-form-content-left-wrapper' style={{ padding: "20px 20px 20px 0px" }}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{PortConstant.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={portVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ maxHeight: '75vh', height: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>

                        {/* Stack Form Start */}
                        {/* <PortSearchForm /> */}

                        <CriteriaItemContainer>
                            {portState.enabledSearchCriteria.portCode ? memoPortCode : null}
                            {portState.enabledSearchCriteria.countryCode ? memoCountryCode : null}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {portState.enabledSearchCriteria.provinceCode ? memoProvinceCode : null}
                            {portState.enabledSearchCriteria.geogInd ? memoGeographicalInd : null}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default PortSearchPanel;
