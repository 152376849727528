export interface PortSearchCriteria{
    
    portCode?: string | null,
    countryCode?: string | null,
    provinceCode?: string | null,
    geogInd?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_PORT_SEARCH_CRITERIA : PortSearchCriteria = {
    portCode: '',
    countryCode: '',
    provinceCode: '',
    geogInd: '',
}