export interface PortEntity{

    id?: number | null,
    portCode?: string | null,
    effectiveDate?: string | null,
    portName?: string | null,
    portEncodeScheme?: string | null,
    expiryDate?: string | null,
    countryCodeId?: string | null,
    countryCode?: string | null,
    provinceCodeId?: string | null,
    provinceCode?: string | null,
    geogInd?: string | null,
    geographicalIndDesc?: string | null,
    createBy?: string | null,
    activeInd: string | null,
    mappingType: string,

    [key: string]: string | boolean | number | null | Object | undefined
}

export const EMPTY_PORT_ENTITY : PortEntity = {

    id: 0,
    portCode: '',
    effectiveDate: '',
    portName: '',
    portEncodeScheme: '',
    expiryDate: '',
    countryCodeId: '',
    countryCode: '',
    provinceCodeId: '',
    provinceCode: '',
    geogInd: '',
    geographicalIndDesc: '',
    createBy: 'By Manual',
    activeInd: 'Y',
    mappingType: "PortEntity",

}
