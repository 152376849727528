import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { PortProvider } from "presentation/store/Port/PortProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import PortMaintenance from "presentation/view/section/Port/PortMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const PortContMain = () => {
    return <ANAInfoWrapper permission={Permission.PORT_MAINTENANCE}>
        <MessageBarWrapper>
            <PortProvider>
                <GridStyles/>
                <PortMaintenance/>
            </PortProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default PortContMain;