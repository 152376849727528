import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { EMPTY_PORT_ENTITY, PortEntity } from "domain/entity/Port/PortEntity";
import { EMPTY_PORT_SEARCH_CRITERIA, PortSearchCriteria } from "domain/entity/Port/PortSearchCriteria";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { PortRepository } from "domain/repository/Port/PortRepo";
import { DropdownProps } from "presentation/model/DropdownProps";
import { PortModel } from "presentation/model/Port/PortModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface PortVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<PortModel>> | ((value: SetStateAction<PortModel>) => void),
    ],
    portRepo:PortRepository,
    masterDataRepo: MasterDataRepository,
}

export const PortVM = ({dispatch, portRepo,masterDataRepo}:PortVMProps) => {
    const [portDispatch] = dispatch;

    const loadDropdownOption = async() => {



        await masterDataRepo.getMasterDataByKey(MasterDataType.COUNTRY).then(
            async countryEntities => {
                await masterDataRepo.getMasterDataByKey(MasterDataType.PROVINCE).then(
                    provinceEntities => {

                        let provinceDropdownOption:{[key:string]: DropdownProps[]} = {};

                        const countryCodeDropdownOption = countryEntities?.map((country)=>({
                            dropdownLabel: country.code,
                            tagLabel: country.code,
                            value: country.code,
                        })) ?? []
                        provinceEntities.forEach(province => {
                            if (province.refCode) {
                                if (!provinceDropdownOption[province.refCode]) {
                                    provinceDropdownOption[province.refCode] = [];
                                }
                                provinceDropdownOption[province.refCode].push({
                                    dropdownLabel: province.code,
                                    tagLabel: province.code,
                                    value: province.code
                                });
                            }
                        });

                        portDispatch(prevState => ({
                            ...prevState,
                            dynamicOptions: {
                                ...prevState.dynamicOptions,
                                countryCodeDropdownOptions: countryCodeDropdownOption,
                                provinceCodeDropdownOptions: provinceDropdownOption,
                            }
                        }))
                    }           
                )
            }           
        )
        
        await masterDataRepo.getMasterDataByKey(MasterDataType.GEO_IND).then(
            geoIndEntities => {
                const geographicalIndDropdownOption = geoIndEntities?.map((geoInd)=>({
                    dropdownLabel: geoInd.code,
                    tagLabel: geoInd.code,
                    value: geoInd.code,
                })) ?? []

                portDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        geographicalIndDropdownOptions: geographicalIndDropdownOption,
                    }
                }))
            }           
        )

    }

    const searchPortList = async(searchCriteria:PortSearchCriteria) => {
        portDispatch(prevState => {
            return {
                ...prevState,
                currentRow: {...EMPTY_PORT_ENTITY},
                currentEditRow: {...EMPTY_PORT_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                selectedRows:[],
                portList: [],
            }
        })
        portRepo.searchPortList(searchCriteria).then(data =>{
            if(data){
                portDispatch(prevState => {
                    return {
                        ...prevState,
                        portList: data,
                    }
                })
            }else{
                portDispatch(prevState => {
                    return {
                        ...prevState,
                        portList: [],
                    }
                })
            }
        })
    }

    const onDelete = async (rows: PortEntity[]) => {

        return await portRepo.onDelete(rows);
    }

    const updateSelectedRows = async (allRows: PortEntity[],updatedRows:PortEntity[]) => {

        portDispatch(prevState => {
            return {
                ...prevState,
                portList: [...allRows],
                selectedRows: updatedRows,
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        portDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }
    const onSearchFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        portDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: PortEntity) => {
        portDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        portDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_PORT_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                selectedRows: [],
            }
        })
    }

    const onSave = async (currentRow: PortEntity) => {
        return portRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        portDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_PORT_ENTITY},
                isAdd: true,
                isShowEditPanel: true,
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        portDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked?"Y":"N",
                },
            }
        })
    } 

    const onResetClick = async (isAdd:boolean) => {
        portDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd?{...EMPTY_PORT_ENTITY}:prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        portDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }
    const onSearchCriteriaResetClick = async () => {
        portDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {...EMPTY_PORT_SEARCH_CRITERIA},
            }
        })
    }
    const onSearchClick = async () => {
        portDispatch(prevState => {
            return {
                ...prevState,
                isShowSearchPanel: !prevState.isShowSearchPanel,
            }
        })
    }

    const onShowLoading = () => {
        portDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        portDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    return {
        onHideLoading: onHideLoading,
        onShowLoading: onShowLoading,
        onSearchClick: onSearchClick,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearchFieldChange: onSearchFieldChange,
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onCheckboxChange: onCheckboxChange,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        onDelete: onDelete,
        loadDropdownOption: loadDropdownOption,
        searchPortList: searchPortList,
    }
}