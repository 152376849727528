import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { PortEntity } from "domain/entity/Port/PortEntity";
import { PortSearchCriteria } from "domain/entity/Port/PortSearchCriteria";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { PortRepository } from "./PortRepo";

export const PortRepoImpl = (): PortRepository => {
    
    const searchUrl = "/v1/searchPortList"

    const searchPortList = async(searchCriteria:PortSearchCriteria) : Promise<PortEntity[]> => {
        
        return axiosPostData(referenceAxiosInstance,`${searchUrl}`,searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onDelete = async(rows: PortEntity[]): Promise<ResponseEntity> => {
        const deleteUrl = "/v1/portDelete"
        return await axiosPostData(referenceAxiosInstance, `${deleteUrl}`, rows);
    }

    const onSave = async(row: PortEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/portSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }


    return {
        onSave: onSave,
        onDelete: onDelete,
        searchPortList : searchPortList,
    }
}
