import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { PortRepoImpl } from "domain/repository/Port/PortRepoImpl";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import { PortVM } from "presentation/viewModel/Port/PortVM";
import { useMemo } from "react";

export const usePortVM = () => {
    const [, setPortState] = usePortTracked();
    const portVM = useMemo(() =>
        PortVM({
            dispatch: [setPortState],
            portRepo: PortRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setPortState])

    return portVM
}