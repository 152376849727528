export interface PortEnabledSearchCriteria {
    all: boolean,
    portCode: boolean,
    countryCode: boolean,
    provinceCode: boolean,
    geogInd: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_PORT_SEARCH_CRITERIA: PortEnabledSearchCriteria = {
    all: true,
    portCode: true,
    countryCode: true,
    provinceCode: true,
    geogInd: true,
}